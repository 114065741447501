import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/default.js";
import { Section } from 'src/components/molecules/Section';
import { Button } from 'src/components/atoms/Button';
import { Video } from 'src/components/atoms/Video';
import { InlineList } from 'src/components/molecules/InlineList';
import { Grid, Column } from 'src/components/molecules/Grid';
import { BottomCTA } from 'src/components/organisms/BottomCTA';
import { SEO } from 'src/containers/SEO';
import { GitHubButton } from 'src/components/atoms/GitHubButton';
import { Box } from 'src/components/atoms/Box';
import { Icon } from 'src/components/atoms/Icon';
import { Card } from 'src/components/atoms/Card';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section centered maxWidth="large" overlap mdxType="Section">
      <Video controls height="56.25%" width="700" youtube="VXvWdrr2yw8" youtubeStart={836} autoPlay round mdxType="Video" />
    </Section>
    <Section noPadding centered mdxType="Section">
      <h2 {...{
        "id": "not-sure-where-to-go-next"
      }}>{`Not sure where to go next?`}</h2>
      <Grid columns="1fr 1fr 1fr" mdxType="Grid">
        <Card mdxType="Card">
  <h3>Read the docs</h3>
  <p>Get started with learning from real conversations with Rasa X.</p>
  <Button to="/docs/rasa-x/" mdxType="Button">Read the docs</Button>
        </Card>
        <Card mdxType="Card">
  <h3>Join the Community Forum</h3>
  <p className="small">
    Get all your questions answered and meet other conversational AI enthusiasts in the Rasa Community Forum.
  </p>
  <Button to="https://forum.rasa.com/" mdxType="Button">Join the forum</Button>
        </Card>
        <Card mdxType="Card">
  <h3>Found a bug?</h3>
  <p>If you've found a bug, or have a feature request, you can open a new issue on the GitHub repo.</p>
  <Button to="https://github.com/RasaHQ/rasa" icon={<Icon icon={['fab', 'github']} mdxType="Icon" />} mdxType="Button">
    Report an issue
  </Button>
        </Card>
      </Grid>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      